<template>
  <div>
    <v-form @submit.prevent="submit">
      <v-row justify="center">
        <v-col cols="2">
          <v-switch
            :input-value="filters.active"
            label="Ativos"
            @input="(value) => handleInput(value, 'active')"
          ></v-switch>
        </v-col>
        <v-col lg="2" md="4" sm="10" cols="12">
          <v-text-field
            filled
            :value="filters.name"
            label="Nome"
            @input="(value) => handleInput(value, 'name')"
          />
        </v-col>
        <v-col lg="2" md="4" sm="10" cols="12">
          <v-text-field
            filled
            :value="filters.trackingPoint"
            label="Ponto de rastreio"
            @input="(value) => handleInput(value, 'trackingPoint')"
          />
        </v-col>
        <v-col lg="2" md="4" sm="10" cols="12">
          <v-text-field
            filled
            :value="filters.impactionPoint"
            label="Ponto de impactação"
            @input="(value) => handleInput(value, 'impactionPoint')"
          />
        </v-col>
        <v-col lg="2" md="4" cols="12">
          <v-text-field
            filled
            :value="filters.type"
            label="Tipo"
            @input="(value) => handleInput(value, 'type')"
          />
        </v-col>
        <v-col lg="2" md="4" cols="12">
          <v-text-field
            filled
            :value="filters.category"
            label="Categoria"
            @input="(value) => handleInput(value, 'category')"
          />
        </v-col>
        <v-col lg="2" md="4" cols="12">
          <v-text-field
            filled
            :value="filters.symptom"
            label="Sintoma"
            @input="(value) => handleInput(value, 'symptom')"
          />
        </v-col>
        <v-col lg="2" md="4" cols="12">
          <v-text-field
            filled
            :value="filters.pathogen"
            label="Patógeno"
            @input="(value) => handleInput(value, 'pathogen')"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-btn @click="clearFilters" color="secondary" class="mr-3" outlined>
          Limpar
        </v-btn>
        <v-btn color="secondary" type="submit"> Buscar </v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  computed: {
    filters() {
      return this.$store.state.pares.filters;
    },
  },

  methods: {
    submit() {
      this.$emit("submit", this.filters);
    },
    handleInput(value, property) {
      this.$store.commit("pares/setPropertyFilters", { value, property });
    },
    clearFilters() {
      this.$store.commit("pares/clearFilters");
      this.$store.commit("pares/clearOptions");
    },
  },
};
</script>

<style></style>
